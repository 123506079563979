<template>
  <div class="pdftranslate_component">
    <!-- <iframe class="translatePdfView" ref="translatePdfView"
            :src="`https://admin.infox-med.com/transelatePdfView/index.html?url=${url}&id=${id}&token=${token}`"
            frameborder="0" noResize></iframe> -->
    <iframe class="translatePdfView" ref="translatePdfView" :src="iframeUrl" frameborder="0"
            noResize></iframe>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    translateInfo: {
      type: Object
    },
    id: {
      type: String
    },
    url: {
      type: String
    },
    pdfViewScroll: Object,
    scaleFactor: Number,
    pdfPageContainerSize: Object
  },
  components: {},
  data () {
    return {
      scale: 1,
      iframeUrl: ''
    }
  },
  methods: {
    setPdfIframeScroll (scroll) {
      const iframeWindow = this.$refs.translatePdfView.contentWindow
      iframeWindow.postMessage({
        key: 'setContainerScroll',
        data: scroll
      }, '*')
    },
    setIframeScaleFactor (scaleFactor) {
      const iframeWindow = this.$refs.translatePdfView.contentWindow
      iframeWindow.postMessage({
        key: 'setScaleFactor',
        data: scaleFactor
      }, '*')
    },
    // setPdfPageDefaultSize () {
    //   const iframeWindow = this.$refs.translatePdfView.contentWindow
    //   setTimeout(() => {
    //     iframeWindow.postMessage({
    //       key: 'pdfPageDefaultSize',
    //       data: this.pdfPageContainerSize
    //     }, '*')
    //   }, 1000)
    // }
  },

  mounted () {
    // this.setPdfPageDefaultSize()
  },
  computed: {
    ...mapGetters(['token'])
  },
  watch: {
    scaleFactor (newVal) {
      this.setIframeScaleFactor(newVal)
    }
  },
  created () {

    // https://www.infox-med.com/transelatePdfView
    // http://192.168.0.102:5501
    this.iframeUrl = `https://www.infox-med.com/transelatePdfView/index.html
?url=${this.url}&id=${this.id}&token=${this.token}&scaleFactor=${this.scaleFactor}&w=${this.pdfPageContainerSize.width}&h=${this.pdfPageContainerSize.height}&v=2`
  }
}
</script>

<style lang="less" scoped>
.pdftranslate_component {
  // position: fixed;
  // top: 0;
  // z-index: 9999;
  width: 100%;
  height: calc(100vh - 33px);
  // padding: 0 20px 10px;
  // background-color: #fff;
  overflow-y: auto;
}
.translatePdfView {
  width: 100%;
  height: 100%;
}
/deep/.pageContainer {
  position: relative;
  width: calc(var(--scale-factor) * 793px);
  height: calc(var(--scale-factor) * 1122px);
  // box-shadow: 0 0 10px 3px #ccc;
  // margin: 0 auto 20px;
  border: 9px solid transparent;
  background-clip: content-box;
  border-image: url('../../../assets/img/pdf/pdfPageShadow.png') 9 9 repeat;
  background-color: rgba(255, 255, 255, 1);
  .text_block {
    position: absolute;
  }
}
</style>
